<template>
    <table>
        <thead>
            <tr>
                <th>TIMS</th>
                <th colspan="2">Free Individual Trekkers</th>
                <th colspan="2">Group Trekkers</th>
                <th colspan="2">Total Trekkers</th>
                <th rowspan="2">All Total Trekkers</th>
            </tr>
            <tr>
                <th>Months</th>
                <th>Non SAARC Nationals</th>
                <th>SAARC Nationals</th>
                <th>Non SAARC Nationals</th>
                <th>SAARC Nationals</th>
                <th>Non SAARC Nationals</th>
                <th>SAARC Nationals</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in rows" :key="row.month">
                <td>{{row.month}}</td>
                <td>{{row.nonSaarcNational_Individual}}</td>
                <td>{{row.saarcNational_Individual}}</td>
                <td>{{row.nonSaarcNational_Group}}</td>
                <td>{{row.saarcNational_Group}}</td>
                <td>{{row.totalNonSaarc_Nationals}}</td>
                <td>{{row.totalSaarc_Nationals}}</td>
                <td>{{row.aLlTotalTrekkers}}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "saarcTable",
        props:['rows'],
        data() {
            return {
            }
        },

    }
</script>

<style lang="scss" scoped>
    table {

        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #1e88e5;
            color: white;
        }
    }

    table, td, th {
        border: 1px solid #ddd;
        text-align: left;
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;

    }

    th, td {
        padding: 15px;
    }

    .tfoot {
        background-color: #d2d2d2;
    }

    .data-input {
        .v-input {
            max-width: 7.1em !important;
        }
    }

    input {
        max-width: 5.7em !important;
    }

    .employee-form-header {
        background-color: #1e88e5;
        height: 3em;
        padding: .6em;

        span {
            color: #ffffff;
            font-size: 1.4em;
        }
    }

    #form_card {
        text-align: center;
    }

    .box-border {
        border: 1px solid;
        padding: 1em;
    }
    .total{
        background-color: rgba(79, 163, 229, 0.8);

    }
</style>